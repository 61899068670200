import React from "react"
import { graphql } from "gatsby"
import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"

import Ornament from "../components/common/Ornament"
function GasPage({ data }) {
  const image = data.file.childImageSharp.fluid

  return (
    <PageLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Gås 2020"
      />
      <section
        id="gas-meny"
        className="flex flex-col justify-center items-center max-w-xl mx-auto px-3"
      >
        <fieldset className="border-cherry border-2 px-2 rounded-md text-center mx-auto menu-box">
          <legend>
            <h2 className="text-2xl uppercase acme text-cherry relative inline-block -mt-6 px-8 bg-transparent">
              Gåsmeny
            </h2>
          </legend>

          <div className="my-6 text-sm">
            <div className="mb-4">
              <h3 className="text-lg my-2 uppercase acme">Förrätt</h3>
              <p className="text-lg italic">
                Svartsoppa & korv på lever och krås med äpple
              </p>
              <p className="text-sm font-bold">alt.</p>
              <p className="text-lg italic">Hummersoppa med trattkantareller</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg my-2 uppercase acme">Huvudrätt</h3>
              <p className="text-lg italic">
                Helstekt gås från Munka Ljungby med rödkål från Åhus, rosenkål,
                Österlenäpple, gåssås, råsockerstekta potatis & plommon{" "}
              </p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg my-2 uppercase acme">Efterrätt</h3>
              <p className="text-lg italic">Skånsk äppelkaka med vaniljsås</p>
            </div>
            <div className="w-6 mx-auto my-8">
              <div className="max-w-full ">
                <Ornament />
              </div>
            </div>
            <p className="text-lg mt-4 italic font-bold">
              {" "}
              Tre rätter, 395kr per person
            </p>

            <p className="text-lg mt-2 italic font-bold">
              {" "}
              Hel gås från Munka-Ljunby 239kr/kg{" "}
            </p>
            <p className="my-4">
              Beställ via{" "}
              <a
                href="mailto:info@clemenskott.se"
                title="Boka"
                className="underline"
              >
                info@clemenskott.se
              </a>{" "}
              / 040-22 22 96
              <br />
              Gäller mellan den 5 - 21 Nov. Endast för avhämtning.
            </p>
          </div>
        </fieldset>
        <div className="w-full px-24 py-8 -ml-8">
          <div className=" blend-multiply"></div>
        </div>
      </section>
    </PageLayout>
  )
}
export const query = graphql`
  {
    file(name: { eq: "gas" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default GasPage
